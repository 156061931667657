import React from "react";
import "./App.css";
import { BrowserRouter, Route, Link } from "react-router-dom";
import { useSelector } from "react-redux";

import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/ProductScreen";
import CartScreen from "./screens/CartScreen";
import SigninScreen from "./screens/SigninScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ProductsScreen from "./screens/ProductsScreen";
import ShippingScreen from "./screens/ShippingScreen";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import ProfileScreen from "./screens/ProfileScreen";
import OrdersScreen from "./screens/OrdersScreen";
import TestScreen from "./screens/TestScreen";
import MyOrderScreen from './screens/MyOrderScreen'

import MenuBar from "./components/MenuBar";
import UsersScreen from "./screens/UsersScreen";
import RatesScreen from "./screens/RatesScreen";
import PrivateRoute from "./components/PrivateRoute";
import AboutUsScreen from "./screens/AboutUsScreen";
import ItemScreen from "./screens/ItemScreen";
import PrivacyPolicy from "./screens/PrivacyPolicy";


function App(props) {
  const userSignin = useSelector(state => state.userSignin);
  const { userInfo } = userSignin;

  const cart = useSelector(state => state.cart);
  const { cartItems } = cart;
  return (
    <BrowserRouter>
      <div className="grid-container">
        <MenuBar history = {props.history}/>
        {/* <aside className="sidebar">
          <h3>Shopping Categories</h3>
          <button className="sidebar-close-button" onClick={closeMenu}>
            x
          </button>
          <ul className="categories">
            <li>
              <Link to="/category/Bol">Bollen</Link>
            </li>
            <li>
              <Link to="/category/Drank">Dranken</Link>
            </li>
          </ul>
        </aside> */}

        <main className="main">
          <div className="content">
            <Route path="/test" component={TestScreen} />
            <Route path="/orders" component={OrdersScreen} />
            <Route path="/users" component={UsersScreen} />
            <PrivateRoute path="/profile" component={ProfileScreen} />
            <Route path="/order/:id" component={OrderScreen} />
            <Route path="/products" component={ProductsScreen} />
            <Route path="/shipping" component={ShippingScreen} />
            <Route path="/payment" component={PaymentScreen} />
            <Route path="/placeorder" component={PlaceOrderScreen} />
            <Route path="/signin" component={SigninScreen} />
            <Route path="/register" component={RegisterScreen} />
            <Route path="/product/:id" component={ProductScreen} />
            <Route path="/cart/:id?" component={CartScreen} />
            <Route path="/category/:id?" component={HomeScreen} />
            <Route path="/myorders" component={MyOrderScreen} />
            <Route path="/rates" component={RatesScreen} />
            <Route path="/aboutus" component={AboutUsScreen} />
            <Route path="/terms" component={PrivacyPolicy} />
            <Route path="/items" component={ItemScreen} />
            <Route path="/" exact={true} component={HomeScreen} />
          </div>
        </main>
        <footer className="footer">Suriwebwinkel BV | Copyright ©2021 All rights reserved </footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
