import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import moment from "moment-timezone";
import "moment/locale/nl";
import CancelIcon from "@material-ui/icons/Cancel";
import Pusher from "pusher-js";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { Link } from "react-router-dom";

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { saveorder, listOrders, deleteOrder } from "../actions/orderAction";
import Loading from "../components/Loading";

import Axios from "axios";

import MaterialTable from "material-table";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 700,
    tableLayout: "fixed",
    whiteSpace: "nowrap"
  },
  button: {
    margin: theme.spacing(1)
  }
}));

export default function OrdersScreen() {
  const [reList, setRelist] = useState(false);

  const orderList = useSelector(state => state.orderList);
  const { orders, loading, error } = orderList;

  const userSignin = useSelector(state => state.userSignin);
  const { userInfo } = userSignin;

  const orderDelete = useSelector(state => state.orderDelete);
  const {
    loading: loadingDelete,
    success: successDelete,
    error: errorDelete
  } = orderDelete;

  const orderUpdateStatus = useSelector(state => state.orderUpdateStatus);
  const {
    loading: loadingUpdateStatus,
    success: succesUpdateStatus,
    error: errorUpdateStatus
  } = orderUpdateStatus;

  const dispatch = useDispatch();
  useEffect(() => {
    const pusher = new Pusher("b8d064248f5bce013fb0", {
      cluster: "us2"
    });
    const newOrderChannel = pusher.subscribe("newOrder");
    newOrderChannel.bind("inserted", data => {
      setRelist(true);
    });

    const channel = pusher.subscribe("messages");
    channel.bind("updated", data => {
      setRelist(true);
    });
  });

  useEffect(() => {
    dispatch(listOrders());
    setRelist(false);
    return () => {};
  }, [successDelete, succesUpdateStatus, reList]);

  const deleteHandler = order => {
    dispatch(deleteOrder(order._id));
  };

  const handleShoppingList = async () => {
    const { data, status } = await Axios.post(
      "/api/orders/trigger-shopping-list",
      {},
      {
        headers: {
          Authorization: "Bearer " + userInfo.token
        }
      }
    );
    if (status === 200) {
      alert("Email List Send.");
    }
  };

  const handleAddressList = async () => {
    const { data, status } = await Axios.post(
      "/api/orders/trigger-address-list",
      {},
      {
        headers: {
          Authorization: "Bearer " + userInfo.token
        }
      }
    );
    if (status === 200) {
      alert("Address was fetched.");
    }
  };

  const handleInvoice = async data => {
    const agree = window.confirm("Are you sure you want to send an INVOICE?");
    if (agree) {
      const orderId = data.orderId;
      const amount = data.totalPrice;
      const customerName = data.userName;
      const email = data.user.email;
      const request = { orderId, amount, customerName, email };
      const { status } = await Axios.post(
        "/api/payment/create-invoice",
        request,
        {
          headers: {
            Authorization: "Bearer " + userInfo.token
          }
        }
      );
      if (status === 200) {
        alert("Invoice was send successfully.");
      }
    }
  };

  const classes = useStyles();

  const formatData = date => {
    if (date) {
      const dateInDate = new Date(date);
      const time = date.split("T")[1].substr(0, 5);
      const formattedVersion = `${dateInDate.getDate()}/${dateInDate.getMonth()}/${dateInDate.getFullYear()} | ${time}`;
      return formattedVersion;
    }
  };

  const convertDate = date => {
    const timeStamp = Date.parse(date);
    const momentStamp = moment(timeStamp);
    return momentStamp.add(7, "hours").format("LLLL");
  };

  const [state, setState] = React.useState({
    columns: [
      { title: "Order-Id", field: "orderId" },
      {
        title: "Date",
        field: "createdAt",
        render: data => <div>{convertDate(data.createdAt)}</div>
      },
      { title: "Total", field: "totalPrice" },
      { title: "User", field: "user.email" },
      {
        title: "Paid",
        field: "isPaid",
        render: data => (
          <Chip
            icon={data.isPaid === true ? <CheckCircleIcon /> : <CancelIcon />}
            label={data.isPaid === true ? "Betaald" : "Niet Betaald"}
            clickable
            color={data.isPaid === true ? "primary" : "secondary"}
            deleteIcon={<DoneIcon />}
          />
        )
      },
      {
        title: "Paid At",
        field: "paidAt",
        render: data => <div>{data.paidAt ? convertDate(data.paidAt) : ""}</div>
      },
      { title: "Delivered", field: "isDelivered" },
      {
        title: "Delivered At",
        field: "deliveredAt",
        render: data => (
          <div>{data.deliveredAt ? convertDate(data.deliveredAt) : ""}</div>
        )
      },
      {
        title: "Status",
        render: data => (
          <Chip
            icon={
              data.orderStatus === "DELIVERED" ? (
                <CheckCircleIcon />
              ) : data.orderStatus === "PENDING" ? (
                <HourglassFullIcon />
              ) : (
                <ShoppingCartIcon />
              )
            }
            label={data.orderStatus}
            clickable
            color={
              data.orderStatus === "DELIVERED"
                ? "primary"
                : data.orderStatus === "PENDING"
                ? "secondary"
                : ""
            }
            deleteIcon={<DoneIcon />}
          />
        ),
        field: "orderStatus"
      },
      {
        title: "Action",
        render: data => (
          <div style={{ display: "flex" }}>
            <Link
              to={{
                pathname: "/order/" + data.orderId,
                state: { email: data.user && data.user.email }
              }}
            >
              <Button
                variant="contained"
                color="primary"
                endIcon={<Icon>details</Icon>}
                className={classes.button}
              >
                Overzicht
              </Button>
            </Link>
            {!data.isPaid && (
              <Button
                variant="contained"
                color="primary"
                endIcon={<Icon>details</Icon>}
                className={classes.button}
                onClick={() => handleInvoice(data)}
              >
                Invoice
              </Button>
            )}
          </div>
        )
      }
    ]
  });

  return loading ? (
    <div className="loading">
      <Loading />
    </div>
  ) : error ? (
    <div>{error}</div>
  ) : (
    <>
      <div className="table-only">
        <MaterialTable
          title="All Orders"
          columns={state.columns}
          data={orders}
          options={{ exportButton: true }}
        />
      </div>
      <div style={{ marginTop: "55px", float: "right", marginRight: "250px" }}>
        <Button
          variant="contained"
          color="primary"
          endIcon={<Icon>details</Icon>}
          className={classes.button}
          onClick={handleShoppingList}
        >
          Send Shopping List
        </Button>
        <Button
          variant="contained"
          color="primary"
          endIcon={<Icon>details</Icon>}
          className={classes.button}
          onClick={handleAddressList}
        >
          Fetch Address List
        </Button>
      </div>
    </>
  );
}
