import React, { useEffect, useState } from "react";
import Products from "../components/Product";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";

import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { listProducts } from "../actions/productAction";
import { listCategories } from "../actions/productAction";
import { getLatestRate } from "../actions/rateAction";

import Loading from "../components/Loading";
import CustomCarousel from "../components/CustomCarousel";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import LoadingSkeleton from "../components/LoadingSkeleton";
import Pagination from "@material-ui/lab/Pagination";
import { getKeyword } from "../global/SharedData";

import Chip from "@material-ui/core/Chip";
import ProductV2 from "../components/ProductV2";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  chip: {
    fontSize: "25px"
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2)
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    paddingBottom: "3px"
  }
}));

const images = [
  {
    imgSrc:
      "https://suriwebwinkel-bucket.s3.amazonaws.com/WhatsApp%20Image%202021-02-09%20at%2010.06.14.jpeg"
  },
  {
    imgSrc:
      "https://suriwebwinkel-bucket.s3.us-east-2.amazonaws.com/WhatsApp%20Image%202021-02-09%20at%2010.06.14%20%281%29.jpeg"
  },
  {
    imgSrc:
      "https://suriwebwinkel-bucket.s3.us-east-2.amazonaws.com/WhatsApp%20Image%202021-02-09%20at%2010.06.13.jpeg"
  }
];

function HomeScreen(props) {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [index, setIndex] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const category = props.match.params.id ? props.match.params.id : "";
  const keyWord = getKeyword();

  const productList = useSelector(state => state.productList);
  const { products, loading, error, page, pages } = productList;

  const mostLatestRate = useSelector(state => state.rateLatest);
  const { loading: loadingLatestRate, latestRate } = mostLatestRate;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLatestRate());
    dispatch(listProducts(pageNumber, category, keyWord)); 
    dispatch(listCategories());
    window.scrollTo(0, 0);
    return () => {};
  }, [category, pageNumber]);

  const submitHandler = e => {
    e.preventDefault();
    dispatch(listProducts(category, searchKeyword));
  };

  const sortHandler = e => {
    setSortOrder(e.target.value);
    dispatch(listProducts(category, searchKeyword, sortOrder));
  };

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const classes = useStyles();
  const loadingSkeleton = ["", "", ""];
  String.prototype.toTitleCase = function() {
    return this.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const getFilterUrl = filter => {
    console.log(filter.page);
  };

  const changePageNumber = (e, page) => {
    setPageNumber(page);
  };
  return (
    <>
      {loading ? (
        <div>
          <div className="loading">
            <Loading />
          </div>
          <ul className="products">
            {loadingSkeleton.map(product => (
              <li key={product._id}>
                <div>
                  <LoadingSkeleton loading />
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <div className="screen-content">
          <CssBaseline />
          <div
            className={classes.heroContent}
            style={{ backgroundColor: "transparent", position: "relative" }}
          >
            <Container maxWidth="sm">
              <Typography
                component="h1"
                variant="h2"
                align="center"
                color="textPrimary"
                gutterBottom
              >
                Suriwebwinkel N.V.
              </Typography>
              <Typography
                variant="h5"
                align="center"
                color="textSecondary"
                paragraph
              >
                Het doel van SURIWEBWINKEL is de band tussen familie leden in
                Nederland en Suriname te verstevigen
              </Typography>
              <div className={classes.heroButtons}>
                <Grid container spacing={2} justify="center">
                  <div className={"banner"}>
                    <CustomCarousel data={images} />
                  </div>
                </Grid>
              </div>
            </Container>
            {props.children}
          </div>
          <ProductV2 data={products} rateEuro={latestRate.euro} />
          <div className={classes.pagination}>
                        
            <Pagination
              count={pages}
              color="primary"
              size="medium"
              page={pageNumber}
              onChange={changePageNumber}
            />
                      
          </div>
        </div>
      )}
    </>
  );
}

export default HomeScreen;
