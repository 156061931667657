import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Paper from '@material-ui/core/Paper';



const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      position: 'absolute',
      overflow: 'scroll',
      maxHeight: 650,
    },
    inline: {
      display: 'inline',
    },
  }));
  
  export default function SearchDropDown() {
    const classes = useStyles();

    const productList = useSelector(state => state.productList);
    const { products, loading, error } = productList;
  
    return (
      <Paper style={{maxHeight: 100, overflow: 'auto'}}>
      <List className={classes.root}>
        {products.map(product=>(
          <Link to={'/product/' + product._id}>
          <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt="Remy Sharp" src={product.image} />
          </ListItemAvatar>
          <ListItemText
            primary={product.name}
            secondary={
              <React.Fragment>
                {product.description.length > 48
                        ? product.description.substring(0, 35) + "..."
                        : product.description}
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider />
        </Link>
        ))}
      </List>
      </Paper>
    );
  }