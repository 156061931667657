import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Stepper from "../components/Stepper";

import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";

import Webcam from "react-webcam";
import axios from "axios";

import { AwesomeButton } from "react-awesome-button";

import {
  createOrder,
  detailsOrder,
  payOrder,
  updateStatusOrder
} from "../actions/orderAction";
import { getPaymentClientSecret } from "../actions/paymentAction";
import PaypalButton from "../components/PaypalButton";
import { makeStyles } from "@material-ui/core/styles";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  useStripe,
  useElements,
  IdealBankElement
} from "@stripe/react-stripe-js";
import Axios from "axios";

const IDEAL_ELEMENT_OPTIONS = {
  style: {
    base: {
      padding: "10px 12px",
      color: "#32325d",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4"
      }
    }
  }
};

const stripePromise = loadStripe(
  "pk_test_51HzwaAH8w5UYmCB0Be5YZmDnAEdhgPPTasgAJTbZa3P5juoOgbrCADKCm8WRqacV8MyXBxvqlzdjdx1Tn3BfLjBg00S5OVDMeW"
);
const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  button: {
    marginLeft: "10px",
    width: "35rem",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  }
}));

function OrderScreen(props) {
  const stripe = useStripe();
  const elements = useElements();

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [nameOfCustomer, setnameOfCustomer] = useState("");
  const [uploadedOrder, setUploadedOrder] = useState(null);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const [orderStatus, setOrderStatus] = useState("");
  const userSignin = useSelector(state => state.userSignin);
  const cart = useSelector(state => state.cart);
  const { userInfo } = userSignin;
  const orderId = props.match.params.id;
  const userEmail = props.location && props.location.state && props.location.state.email;

  const orderPay = useSelector(state => state.orderPay);
  const {
    loading: loadingPay,
    success: successPay,
    error: errorPay
  } = orderPay;

  const orderUpdateStatus = useSelector(state => state.orderUpdateStatus);
  const {
    loading: loadingUpdateStatus,
    success: succesUpdateStatus,
    error: errorUpdateStatus,
    order: orderStatusUpdate
  } = orderUpdateStatus;

  const clientSecret = useSelector(state => state.getPaymentClientSecret);
  const {
    loading: loadingGetPaymentClientSecret,
    success: successGetPaymentClientSecret,
    paymentClientSecret,
    error: errorGetPaymentClientSecret
  } = clientSecret;

  const orderDetails = useSelector(state => state.orderDetails);
  const { loading, order, error } = orderDetails;

  const notifySupermarketHandler = async()=>{
    const {status} = await Axios.post(`/api/orders/trigger-supermarket-order?orderId=${orderId}`,{},{
      headers:{
        'Authorization':'Bearer ' + userInfo.token
      }
    });
    if(status === 200){
      alert('Supermarket notified.')
    }
  }
  const handlePaymentManually = async()=>{
    const agree = window.confirm("Bent u zeker deze betaling te updaten, Als er niet betaalt is in werkelijkheid dan bent u verantwoordelijk voor dit bedrag en deze order");
    if(agree){
      const request={
        orderId,
        email:userEmail
      }
      const {status} = await Axios.post(`/api/payment/paid`,request,{
        headers:{
          'Authorization':'Bearer ' + userInfo.token
        }
      });
      if(status === 200){
        alert('Payment update completed.')
      }
    }
  }
  

  //Making Payment
  const handleSubmit = async event => {
    event.preventDefault();
    handleToggle();
    if (!stripe || !elements) {
      return;
    }
    let cSecret;
    await Axios.get(
      `/api/payment/secret/${order.totalPrice}/${order.orderId}`,
      {
        headers: {
          Authorization: "Bearer " + userInfo.token
        }
      }
    ).then(data => {
      cSecret = data.data.client_secret.client_secret;
    });

    const idealBank = elements.getElement(IdealBankElement);
    const { error } = await stripe.confirmIdealPayment(cSecret, {
      payment_method: {
        ideal: { idealBank },
        billing_details: {
          name: userInfo.name,
          email: userInfo.email
        },
        metadata: {
          orderIdFromStore: orderId
        }
      },
      return_url: `http://dev.suriwebwinkel.nl/myorders`
    });

    handleClose();
    if (error) {
      // Show error to your customer.
      console.log(error.message);
    }

    // Otherwise the customer will be redirected away from your
    // page to complete the payment with their bank.
  };

  const dispatch = useDispatch();
  useEffect(() => {
    if (paymentClientSecret) {
      console.log(
        "Calling iDeal: " +
          JSON.stringify(paymentClientSecret.client_secret.client_secret)
      );
    }
    if (!userInfo) {
      props.history.push("/signin?redirect=myorders");
    }
    if (successPay) {
      props.history.push("/profile");
    } else {
      dispatch(detailsOrder(props.match.params.id));
    }
    return () => {};
  }, [
    successPay,
    succesUpdateStatus,
    successGetPaymentClientSecret,
    uploadedOrder
  ]);

  const handlePayment = ()=>{

  }

  const handleSuccessPayment = paymentResult => {
    dispatch(payOrder(order, loadingUpdateStatus));
  };

  //Temporarily
  const getClientSecret = () => {
    dispatch(getPaymentClientSecret(order.totalPrice));
    handleToggle();
    // props.history.push("/profile");
    // Call iDeal bank
  };

  const statusHandler = (order, status) => {
    dispatch(updateStatusOrder(order._id, status));
    props.history.push("/order/" + orderId);
  };

  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);

  function dataURItoBlob(dataURI) {
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);
    var mimeString = dataURI
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    const fileBlob = dataURItoBlob(imageSrc);
    const bodyFormData = new FormData();
    bodyFormData.append("image", fileBlob, orderId);
    axios
      .post("/api/uploads/s3", bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(async response => {
        const uploadedImage = response.data;
        const request = {
          orderId,
          imgSrc: uploadedImage
        };
        const uploadedOrder = await axios.put(`/api/orders/image/`, request);
        setUploadedOrder(uploadedOrder);
      })
      .catch(err => {
        console.log(err);
      });
  }, [webcamRef, setImgSrc]);

  return open ? (
    <div>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  ) : loading ? (
    <div>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  ) : error ? (
    <div>{error}</div>
  ) : (
    <div className="screen-content">
      <div className="placeorder">
        {userInfo && !userInfo.isAdmin && <Stepper step="4"></Stepper>}
        <div className="placeorder-info">
          {userInfo && userInfo.isAdmin && (
            <div>
              <Link to="/orders">
                <AwesomeButton type="primary" className={classes.button}>
                  Alle Orders
                </AwesomeButton>
              </Link>
              <div style={{float:'right'}} onClick={()=>notifySupermarketHandler()}>
              <AwesomeButton type="primary" className={classes.button}>
                  Notify Supermarket
                </AwesomeButton>
              </div>
            </div>
          )}
          <div className="box-shadow">
            {userInfo && !userInfo.isAdmin && (
              <h2 className="bold">Bedankt!, Uw bestelling is geplaatst</h2>
            )}
            {userInfo && userInfo.isAdmin && <h3>Naam: {order.userName}</h3>}
            <h3 className="bold">Bestellings Nummer: {orderId}</h3>
            <h3 className="bold">
              Verzendings Methode:{" "}
              {order.pickUp === true ? "Afhalen" : "Bezorgen"}
            </h3>
          </div>
          {userInfo && !userInfo.isAdmin && (
            <div className="box-shadow">
              <Chip
                label={"Process van uw bestelling"}
                clickable
                color="primary"
              />
              <Timeline align="alternate">
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot color="primary" />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>Bestelling Ontvangen</TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot color="primary" />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>In behandeling</TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot
                      color={
                        order.orderStatus === "ACCEPTED"
                          ? "primary"
                          : order.orderStatus === "DELIVERED"
                          ? "primary"
                          : "grey"
                      }
                    />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>Bestelling geaccepteerd</TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot
                      color={
                        order.orderStatus === "DELIVERED" ? "primary" : "grey"
                      }
                    />
                  </TimelineSeparator>
                  <TimelineContent>Bestelling afgeleverd</TimelineContent>
                </TimelineItem>
              </Timeline>
            </div>
          )}
          <div className="box-shadow">
            <Chip label={"Datum & Notes"} clickable color="primary" />
            <div>Datum: {order.deliveryPickUpDate}</div>
            <div>Notes: {order.notes}</div>
          </div>
          {!order.pickUp ? (
            <div className="box-shadow">
              <Chip label={"Bezorg adres"} clickable color="primary" />
              <div>
                {order.shipping.address}, {order.shipping.resort} #
                {order.shipping.homeNumber}, {order.shipping.district},{" "}
                {order.shipping.country}
              </div>
              <div>
                {order.isDelivered
                  ? "Afgeleverd op " + order.deliveredAt
                  : "Nog niet afgeleverd"}
              </div>
            </div>
          ) : (
            userInfo &&
            !userInfo.isAdmin && (
              <div className="box-shadow">
                <Chip label={"Afhaal Adres"} clickable color="primary" />
                <div>Suriname, Paramaribo, Doebestraat #3, Welgelegen</div>
                <div>
                  {order.isDelivered
                    ? "Opgehaald op " + order.deliveredAt
                    : "Nog niet opgehaald."}
                </div>
              </div>
            )
          )}

          <div className="box-shadow">
            <Chip label={"Betaling"} clickable color="primary" />
            <div>Betalings Methode: {order.payment.paymentMethod}</div>
            <div>
              {order.isPaid ? "Betaald op " + order.paidAt : "Nog niet betaald"}
            </div>
            {userInfo && userInfo.isAdmin && (
              <button onClick={()=>handlePaymentManually()}>Betaal.</button>
            )}
          </div>
          <div className="box-shadow">
            <Chip label={"Ontvangers gegevens"} clickable color="primary" />
            <div>Ontvangers Naam: {order.receiverName}</div>
            <div>Ontvangers Nummer: {order.receiverPhoneNumber}</div>
          </div>
          <div className="box-shadow">
            <ul className="cart-list-container">
              <li>
                <h3>Shopping Cart</h3>
              </li>
              {order.orderItems.length === 0 ? (
                <div>Cart is empty</div>
              ) : (
                order.orderItems.map(item => (
                  <>
                    <li key={item._id}>
                      <div className="cart-image">
                        <input type='checkbox'/>
                        <img src={item.image} alt="product" />
                      </div>
                      <div className="cart-name">
                        <div>
                          <Link to={"/product/" + item.product}>
                            {item.name}
                          </Link>
                        </div>
                        <div>Aantal: {item.qty}</div>
                      </div>
                      {/* <div className="cart-price">€{item.price}</div> */}
                    </li>
                  </>
                ))
              )}
            </ul>
          </div>
        </div>
        <div className="placeorder-action ">
          <ul>
            <li className="placeorder-actions-payment payment">
              {!order.isPaid && order.payment.paymentMethod !== "Cash" && (
                <div>
                  <h3 className="bold">Bank Information</h3>
                  <form onSubmit={handleSubmit}>
                    <IdealBankElement options={IDEAL_ELEMENT_OPTIONS} />
                    <Button
                      variant="contained"
                      color="primary"
                      className="button primary full-width custom-button"
                      endIcon={<Icon>payment</Icon>}
                      type="submit"
                      disabled={!stripe}
                    >
                      Pay
                    </Button>
                  </form>
                </div>
              )}
            </li>
            {!order.isPaid && (
              <div>
                <Divider component="li" />
              </div>
            )}
            <li>
              <h3 className="bold">Bestellings Overzicht</h3>
            </li>
            <li>
              <div>Items</div>
              <div>€{order.itemPrice}</div>
            </li>
            {order.shippingPrice !== 0 && (
              <li>
                <div>Shipping</div>
                <div>€{order.shippingPrice}</div>
              </li>
            )}
            <li>
              <div>Korting</div>
              <div>€{order.taxPrice}</div>
            </li>
            <Divider component="li" variant="inset" />
            <li>
              <div className="total-price">Totaal</div>
              <div className="total-price">€{order.totalPrice}</div>
            </li>
            {((userInfo && userInfo.isAdmin) || (userInfo && userInfo.isDriver)) && (
              <div>
                <Divider component="li" />
                <h3 className="bold admin">Admin Actions</h3>
                {order.orderStatus === "PENDING" ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    className="button primary full-width custom-button"
                    endIcon={<Icon>checkbox</Icon>}
                    type="submit"
                    disabled={!stripe}
                    onClick={() => statusHandler(order, "ACCEPTED")}
                  >
                    Accept
                  </Button>
                ) : order.orderStatus === "ACCEPTED" ? (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      className="button primary full-width custom-button"
                      endIcon={<Icon>home</Icon>}
                      type="submit"
                      disabled={!stripe}
                      onClick={() => statusHandler(order, "DELIVERED")}
                    >
                      DELIVER
                    </Button>

                    <div className="webcam">
                      <Webcam
                        audio={false}
                        ref={webcamRef}
                        height={400}
                        width={400}
                        screenshotFormat="image/jpeg"
                        videoConstraints={{
                          facingMode: { exact: "environment" }
                        }}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        className="button primary full-width custom-button"
                        endIcon={<PhotoCameraIcon />}
                        onClick={capture}
                      >
                        Capture
                      </Button>
                    </div>
                  </>
                ) : (
                  <div>
                    <h2>
                      <Chip
                        icon={<CheckCircleIcon />}
                        label={"Order Completed"}
                        clickable
                        color="primary"
                        deleteIcon={<DoneIcon />}
                        className="full-width"
                      />
                    </h2>
                  </div>
                )}

                {loadingUpdateStatus ? (
                  <div className="loading">
                    <Loading />
                  </div>
                ) : (
                  ""
                )}
                <div></div>
              </div>
            )}
            {order.imgSrc && <img src={order.imgSrc} />}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default OrderScreen;
