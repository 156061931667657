import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';
import { ArrowNext } from '../arrows/ArrowNext';
import { ArrowPrev } from '../arrows/ArrowPrev';


const ButtonPrev = styled('button')`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${themeGet('colors.white', '#ffffff')};
  color: ${themeGet('colors.primary.regular', '#009E7F')};
  padding: 0;
  border-radius: 20px;
  box-shadow: ${themeGet('shadows.base', '0 3px 6px rgba(0, 0, 0, 0.16)')};
  border: 0;
  outline: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 40px;
  margin-top: -20px;
  z-index: 99;
`;

const ButtonNext = styled('button')`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: ${themeGet('colors.primary.regular', '#009E7F')};
  padding: 0;
  border-radius: 20px;
  box-shadow: ${themeGet('shadows.base', '0 3px 6px rgba(0, 0, 0, 0.16)')};
  border: 0;
  outline: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 40px;
  margin-top: -20px;
  z-index: 99;
`;

const ButtonGroupWrapper = styled('div')``;

const PrevButton = ({ onClick, children }) => {
  return (
    <ButtonPrev
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      className='prevButton'
    >
      {children}
    </ButtonPrev>
  );
};
const NextButton = ({ onClick, children }) => {
  return (
    <ButtonNext
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      className='nextButton'
    >
      {children}
    </ButtonNext>
  );
};

const ButtonGroup = ({ next, previous }) => {

  return (
    <ButtonGroupWrapper c>
        <>
          <PrevButton onClick={() => previous()}>
            <ArrowPrev />
          </PrevButton>
          <NextButton onClick={() => next()}>
            <ArrowNext />
          </NextButton>
        </>
      {/* if prop isRtl true swap prev and next btn */}
    </ButtonGroupWrapper>
  );
};

  const responsive = {
    desktop: {
      breakpoint: { max: 1680, min: 1024 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

function CustomCarousel({
  data,
  autoPlay = true,
  infinite = true
}) {

  return (
    <div className="body">
      <Carousel
        arrows={true}
        responsive={responsive}
        showDots={false}
        slidesToSlide={1}
        infinite={infinite}
        autoPlay={autoPlay}
        autoPlaySpeed={3000}
        renderButtonGroupOutside={true}
        additionalTransfrom={0}
        // {...props}
        // use dir ltr when rtl true
      >
        {data.map((item, index) => {
          return (
            <div style={{ padding: "0 15px", overflow: "hidden" }} key={index}>
              <a
                href={item.link}
                style={{ display: "flex", cursor: "pointer" }}
              >
                <img
                  src={item.imgSrc}
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "block",
                    position: "relative"
                  }}
                />
              </a>
            </div>
          );
        })}
      </Carousel>
      </div>
  );
}

export default CustomCarousel;