import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import { IconButton } from "@material-ui/core";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import StarBorder from '@material-ui/icons/StarBorder';


import { Link } from "react-router-dom";
import {Fruits} from '../icons/Fruits';
import {Drinks} from '../icons/Drinks';
import {MeatFish} from '../icons/MeatFish';
import {Snacks} from '../icons/Snacks';
import {Vegetables} from '../icons/Vegetables';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PeopleIcon from '@material-ui/icons/People';
import ListAltIcon from '@material-ui/icons/ListAlt';
import EuroIcon from '@material-ui/icons/Euro';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import ShopTwoIcon from '@material-ui/icons/ShopTwo';






import { useDispatch, useSelector } from "react-redux";

import { logout } from "../actions/userActions";

const useStyles = makeStyles({
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  },
  customHoverFocus: {
    color: "white"
  }
});

export default function SideBar(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false
  });
  const [open, setOpen] = React.useState(false);
  

  const userSignin = useSelector(state => state.userSignin);
  const { userInfo } = userSignin;

  const categoryList = useSelector(state => state.productCategories);
  const {categories} = categoryList

  const dispatch = useDispatch();

  const handleClick = () => {
    setOpen(!open);
  };


  const handleLogout = (anchor) => {
    dispatch(logout());
  };

  const toggleDrawer = (anchor, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

    String.prototype.toTitleCase = function () {
      return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
  };

  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom"
      })}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <h3 className="bold">Welcome {userInfo ? userInfo.name : "User"}</h3>
        </ListItem>
        <Divider />
        <Link to="/" className="color_black">
          <ListItem button key={"Home"}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              primary={"Home"}
              onClick={toggleDrawer(anchor, false)}
            />
          </ListItem>
        </Link>
        <Link to="/aboutus" className="color_black">
          <ListItem button key={"Over ons"}>
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText
              primary={"Over ons"}
              onClick={toggleDrawer(anchor, false)}
            />
          </ListItem>
          </Link>
          <Link to="/terms" className="color_black">
          <ListItem button key={"Over ons"}>
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText
              primary={"Terms & Conditions"}
              onClick={toggleDrawer(anchor, false)}
            />
          </ListItem>
          </Link>
      </List>
      <Divider />
      {userInfo && userInfo.isAdmin && (
        <List>
          <Link to="/orders" className="color_black">
            <ListItem 
              button
              key={"All Orders"}
              onClick={toggleDrawer(anchor, false)}
            >
              <ListItemIcon>
                <SaveAltIcon />
              </ListItemIcon>
              <ListItemText
                primary={"All Orders"}
                onClick={toggleDrawer(anchor, false)}
              />
            </ListItem>
          </Link>
          <Link to="/users" className="color_black">
            <ListItem button key={"All Users"}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText
                primary={"All Users"}
                onClick={toggleDrawer(anchor, false)}
              />
            </ListItem>
          </Link>
          <Link to="/products" className="color_black">
            <ListItem button key={"Manage Products"}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Manage Products"}
                onClick={toggleDrawer(anchor, false)}
              />
            </ListItem>
          </Link>
          <Link to="/rates" className="color_black">
            <ListItem button key={"Manage Rates"}>
              <ListItemIcon>
                <EuroIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Manage Rates"}
                onClick={toggleDrawer(anchor, false)}
              />
            </ListItem>
          </Link>
        </List>
      )}
      {userInfo && userInfo.isDriver && (
        <List>
          <Link to="/orders" className="color_black">
            <ListItem 
              button
              key={"All Orders"}
              onClick={toggleDrawer(anchor, false)}
            >
              <ListItemIcon>
                <SaveAltIcon />
              </ListItemIcon>
              <ListItemText
                primary={"All Orders"}
                onClick={toggleDrawer(anchor, false)}
              />
            </ListItem>
          </Link>
        </List>
      )}
      <Divider />
      <List>
        {userInfo && (
          <div>
            <ListItem button onClick={handleClick}>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Profile & Orders" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to="/profile" className="color_black">
                  <ListItem button className={classes.nested} onClick={toggleDrawer(anchor, false)}  >
                    <ListItemIcon>
                      <ContactMailIcon />
                    </ListItemIcon>
                    <ListItemText primary={"My Profile"} />
                  </ListItem>
                </Link>
                <Link to="/myorders" className="color_black">
                <ListItem button className={classes.nested} onClick={toggleDrawer(anchor, false)}  >
                    <ListItemIcon>
                      <ShopTwoIcon />
                    </ListItemIcon>
                    <ListItemText primary={"My Orders"} />
                  </ListItem>
                  </Link>
              </List>
            </Collapse>
          </div>
        )}
        <ListItem>
          <h3 className="bold">Categories</h3>
        </ListItem>
        {categories.map(category => {
          return (
            <Link to={`/category/${category}`} className="color_black">
              <ListItem button onClick={toggleDrawer(anchor, false)}>
                <ListItemIcon>
                  {category === "drinks" ? (
                    <Drinks />
                  ) : category === "snacks" ? (
                    <Snacks />
                  ) : category === "fruits" ? (
                    <Fruits />
                  ) : category.includes("meat & fish") ? (
                    <MeatFish />
                  ) : (
                    <Vegetables />
                  )}
                </ListItemIcon>
                <ListItemText primary={category.toTitleCase()} />
              </ListItem>
            </Link>
          );
        })}
      </List>
    </div>
  );

  return (
    <div>
      <React.Fragment key={"left"}>
        <IconButton
          onClick={toggleDrawer("left", true)}
          className={classes.customHoverFocus}
        >
          <MenuIcon />
        </IconButton>
        <SwipeableDrawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
          onOpen={toggleDrawer("left", true)}
        >
          {list("left")}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
